import React, { useState, useRef, useEffect } from 'react';
import SanityImage from "gatsby-plugin-sanity-image";

import fscreen from 'fscreen';
import FocusLock from 'react-focus-lock';
import { NextButton, PreviousButton, GoFullscreenButton, ExitFullscreenButton, ZoomButton } from "../controls/galleryControls"
import { ImageMemoized } from '../ImageMemoized';
import { eagerNeightborsLoading } from "../../helpers/littleHelpers"

/*
https://swiperjs.com/react
https://swiperjs.com/swiper-api
*/

import SwiperCore, { Controller, Thumbs, Navigation, Keyboard, Zoom  } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/thumbs/thumbs.scss';
import 'swiper/components/zoom/zoom.scss';
import 'swiper/components/controller/controller.scss';

SwiperCore.use([Controller, Navigation, Thumbs, Keyboard, Zoom]);

const ImageThumbMemoized = React.memo( ({image}) => {
    return <>
        <SanityImage
            {...image}
            alt=""
            key={`${image._key}-thumb-image`}
            className="thumbsImage"
            width={200}
            config={{
                quality: 70,
                fit: "min",
                auto: "format",
                crop: "entropy"
            }}
        ></SanityImage>
    </>
})

const ImageGallery = (props) => {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [mobileFullscreen, setMobileFullscreen] = useState("");
    const [swiperZoomed, setSwiperZoomed] = useState(false);
    const maximizableElement = useRef(null);
    const swiperRef = useRef(null);
    const controlsRef = useRef(null);

    useEffect(() => {
        swiperRef.current?.swiper.updateSize();
        
    }, [isFullscreen]);


    fscreen.onfullscreenchange = fullscreenChangeStateHandler;
    function fullscreenChangeStateHandler() {

        if (fscreen.fullscreenElement !== null) {
            setIsFullscreen(true)
        } else {
            setIsFullscreen(false)
        }

    }

    const toggleZenMode = () => {
        const galleryObject = maximizableElement.current;
        setSwiperZoomed(false);
        
        if (fscreen.fullscreenEnabled === true ) {
            if (isFullscreen === true) {
                fscreen.exitFullscreen()
            } else {
                fscreen.requestFullscreen(galleryObject);
            } 
        } else {

            const aboveDividerElement = document.getElementsByClassName("divider-ornament")[0]

            if (isFullscreen === false) {
                setMobileFullscreen("mobile");
                setIsFullscreen(true);
                document.getRootNode().body.style.position = 'fixed';
                document.getRootNode().body.style.overflow = 'hidden';
                window.setTimeout(() => {document.activeElement.blur()}, 100)
                zoomToggle(); //this somehow debugs mobile layout shift on fullscreen mode, - the only solution I found

            } else {
                document.getRootNode().body.style.position = '';
                document.getRootNode().body.style.overflow = '';
                const galleryPositionY = aboveDividerElement.offsetTop + aboveDividerElement.offsetHeight;
                setMobileFullscreen("");
                setIsFullscreen(false);
                window.setTimeout(() => {document.activeElement.blur()}, 100)
                zoomToggle(); //this somehow debugs mobile layout shift on fullscreen mode, - the only solution I found
                window.scrollTo(0, galleryPositionY)

            }
        }

    }

    const releaseMobileFocus = () => {
        window.matchMedia("not (pointer: fine)").matches && window.setTimeout(() => {document.activeElement.blur()}, 450)
    }

    const setControlsContainerSpotted = (swiperInstance) => {
        if ( controlsRef.current !== null && swiperInstance.realIndex !== 1 ) {
            controlsRef.current.classList.add("spotted")
        }
    }

    const onZoomChange = () => {
        if (swiperRef.current.swiper.zoom.currentScale !== 1) {
            setSwiperZoomed(false)
        } else {
            setSwiperZoomed(true)
        }
    }

    const zoomToggle = () => {
        setSwiperZoomed(!swiperZoomed);
        swiperRef.current.swiper.zoom.toggle()
        window.setTimeout(() => {document.activeElement.blur()}, 50)
    }

    const nextSlide = () => {
        setSwiperZoomed(false)
        swiperRef.current.swiper.zoom.out();
        window.setTimeout(() => {swiperRef.current.swiper.slideNext(450, true)}, 150)
    }

    const prevSlide = () => {
        setSwiperZoomed(false)
        swiperRef.current.swiper.zoom.out();
        window.setTimeout(() => {swiperRef.current.swiper.slidePrev(450, true)}, 150)
    }

    const getImageWidth = ( image ) => {
        /*
        * unlike in Photosets.js, maximizableElement.current and swiperRef.current 
        * are null when images are generated. Before I investigate why this happens,
        * I can't set matching original image width to blurry placeholder, otherwise
        * it will trigger rerendering and repeated assets download on fullscreen toggle
        */
       
        // if (swiperRef.current === null) {
        //     return "unset";
        // } if (!isFullscreen) {
        //     const activeSlideAspectRatio = image.asset.metadata.dimensions.aspectRatio;
        //     const mainImageHeight = getComputedStyle(swiperRef.current).height;
        //     console.log(getComputedStyle(swiperRef.current))
        //     const width = `${mainImageHeight.replace(/px/, "") * activeSlideAspectRatio}px`;
        //     return width;
            
        // } if (isFullscreen) {
        //     const activeSlideAspectRatio = image.asset.metadata.dimensions.aspectRatio;
        //     const mainImageHeight = getComputedStyle(swiperRef.current).height;
        //     console.log(getComputedStyle(swiperRef.current))
        //     const width = `${mainImageHeight.replace(/px/, "") * activeSlideAspectRatio}px`;
        //     return width;
        // }

    }
    const mainSwiperResizeHandler = () => {
         console.log("resize event")
        if (swiperRef.current !== null) {
            swiperRef.current.swiper.updateSize()
        }
    }



    return (
        <>
        <FocusLock 
            disabled={!isFullscreen}
            returnFocus={true}>
            <section 
                ref={maximizableElement}
                className={`imageGallery ${isFullscreen ? "fullscreen" : "default"} ${mobileFullscreen}`}
            >
                <h2 className="hidden">Фотогалерея</h2>
                <Swiper
                    key="thumbsSwiperArea"
                    className="swiper-container-thumbs"
                    modules={[Thumbs]}
                    onSwiper={setThumbsSwiper}
                    slidesOffsetAfter={10}
                    watchSlidesVisibility
                    watchSlidesProgress
                    slidesPerView={5}
                    centeredSlides={true}
                    grabCursor={true}
                    loop={true}
                    updateOnWindowResize={true}
                    resizeObserver={true}
                    breakpoints={{
                        20: {
                            slidesPerView: 1,
                        },
                        320: {
                            slidesPerView: 3,
                        },
                        600: {
                            slidesPerView: 5,
                        },
                        1100: {
                            slidesPerView: 7,
                        }
                    }}
                >   
                    {
                        props.images.map((image) => (
                            <SwiperSlide key={`${image._key}-thumb`} className="swiper-slide">
                                <ImageThumbMemoized image={image} />
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
                <Swiper 
                    key="mainSwiperArea"
                    ref={swiperRef}
                    className={`big-image-horizontal${isFullscreen ? "-fs" : ""}`}
                    modules={[Thumbs]}
                    thumbs={{ swiper: thumbsSwiper }}
                    resizeObserver={true}
                    observeParents={true}
                    observer={true} 
                    spaceBetween={16}
                    slidesPerView={1}
                    initialSlide={2}
                    grabCursor={true}
                    keyboard={{
                        enabled: true,
                        onlyInViewport: false,
                    }}
                    zoom={{
                        enabled: true,
                        maxRatio: 2.3,
                    }}
                    allowSlidePrev={!swiperZoomed}
                    allowSlideNext={!swiperZoomed}
                    onResize={mainSwiperResizeHandler}
                    onZoomChange={onZoomChange}
                    loop={true}
                    speed={450}
                    onSlideChange={(sw) => {releaseMobileFocus(); setControlsContainerSpotted(sw); eagerNeightborsLoading(sw); }}
                    containerModifierClass="big-image-"
                    navigation={{
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    }}
                >
                    {
                        props.images.map((image, index) => (
                            <SwiperSlide key={`${index}-main`} >
                                <div className="swiper-zoom-container">
                                    <ImageMemoized 
                                        image={image} 
                                        index={index} 
                                        imagesCount={props.images.length}
                                        // styleWidth={getImageWidth(image)} 
                                    />
                                </div>
                            </SwiperSlide>
                        ))
                    }
                    <div className="controls-container" ref={controlsRef} >
                        <ZoomButton passClassName={`zoom-button ${!isFullscreen && "invisible"}`} onClickFunction={zoomToggle} />
                        <PreviousButton passClassName="swiper-button-prev" ariaLabel="Предыдущее фото" onClickFunction={prevSlide} />
                        { isFullscreen === true ? ( 

                            <ExitFullscreenButton passClassName="fullscreenToggle" ariaLabel="Выйти из режима просмотра" onClickFunction={toggleZenMode} /> 
                            ) 
                        : (
                            <GoFullscreenButton passClassName="fullscreenToggle" ariaLabel="Перейти к просмотру" onClickFunction={toggleZenMode} />
                            ) 
                        }
                        <NextButton passClassName="swiper-button-next" ariaLabel="Следующее фото" onClickFunction={nextSlide}  />
                        <ZoomButton passClassName={`zoom-button invisible`} onClickFunction={zoomToggle} />
                    </div>
                </Swiper> 
            </section>
        </FocusLock>
        <div className="divider-ornament" />
        </>
    );
}
 
export default ImageGallery;