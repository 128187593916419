import React from "react"

const LevelsDescription = (props) => {

    return (
        <>  
            <section className="levelsDescriptionSection">
            <h2>Описание</h2>
                <dl>
                    {
                        props.levelsData.map((level) => (
                            <div key={level._key} className="descriptionPair" >
                                <dt>{level.levelName}</dt>
                                <dd>{level.levelDescription}</dd>
                            </div>
                        ))
                    }
                </dl>
            </section>
        </>
    )
}

export default LevelsDescription;