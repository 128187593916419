import React, { useContext } from "react"
import { MainContext } from "../../helpers/MainContext";


const TellUsMore = () => {
    const {setMainFormVisibility} = useContext(MainContext);
    
    const showFindHouseModal = (e) => {
        e.preventDefault();
        setMainFormVisibility(true);
    }

    return (
    <p className="quote">
        Предложения на сайте всегда актуальные, но не исчерпывающие.
        Если вы не нашли что искали, - <nobr><button className="tellUsMore" onClick={showFindHouseModal}>расскажите</button></nobr>, и мы подумаем, что можем предложить.
    </p>
    );
}

export default TellUsMore;