import React from "react";
import { graphql } from 'gatsby'

import Layout from "../components/layout"
import CriticalHead from "../components/CriticalHead"
import FirstScreen from "../components/for-mansion-objects/FirstScreen"
import ImageGallery from "../components/for-mansion-objects/imageGallery"
import PriceSection from "../components/for-mansion-objects/priceSection"
import ObjectDetails from "../components/for-mansion-objects/ObjectDetails"
import LevelsDescription from "../components/for-mansion-objects/LevelsDescription"
import CTAbutton from "../components/ObjectsCtaButton"
import TellUsMore from "../components/for-mansion-objects/TellUsMore.js"
import KoshevickiyIntroduction from "../components/KoshevickiyIntroduction.js"
import ToMainBottomLink from "../components/for-mansion-objects/toMainBottomLink.js"
import GetDPFbutton from "../components/for-mansion-objects/GetPDFbutton"
import FormsModal from "../components/forms/FormsModal"
import ObjectsFormModal from "../components/forms/ObjectsFormModal"
import ObjectsRentModal from "../components/forms/ObjectsRentModal"
import ObjectsPresentationModal from "../components/forms/ObjectsPresentationModal"

 
export const query = graphql`
    query MansionObjectsQuery($id: String!) {
        mansion: sanityMansion (id: { eq: $id }) {
            id
            objectID
            slug {
                current
            }
            orderPriority
            name
            metaDescription
            priceOnDemand
            rentPossible
            rentOccupied
            sellPossible
            currencyOfRent
            currencyOfSell
            priceToRent
            priceToSell
            imgFront {
                _key
                ...ImageWithPreview
                asset {
                    metadata {
                        dimensions { 
                            aspectRatio
                            height
                            width
                        }
                    }
                    url
                }
            }
            imgGallery {
                _key
                ...ImageWithPreview
                asset {
                    metadata {
                        dimensions { 
                            aspectRatio
                            height
                            width
                        }
                    }
                }
            }
            presetation {
                asset {
                    size
                    url
                }
            }
            houseLevelsDescription {
                _key
                levelDescription
                levelName
            }
            siteArea
            siteBuildings
            siteProperty
            siteStatus
            siteType
            siteResources
            houseArea
            housePool
            houseStatus
            houseYear
            houseFloors
            houseBedrooms
            }
        }
    `

const MansionObjectTemplate = ({ data }) => {
    let name = data.mansion.name;
    let description = data.mansion.metaDescription;
    let presentation = data.mansion.presetation;
    const {priceOnDemand, sellPossible, rentPossible, rentOccupied, currencyOfSell, currencyOfRent, priceToSell, priceToRent} = data.mansion;
    const {
        houseArea, houseBedrooms, houseFloors, objectID,
        housePool, houseStatus, houseYear,
        siteArea, siteBuildings, siteProperty,
        siteStatus, siteType, siteResources} = data.mansion;
    const {houseLevelsDescription} = data.mansion;
    const { url } = data.mansion.imgFront.asset;
    const imageRepresentativeURL = `${url}/?fit=crop&w=1200&h=900&v=2021-11-18`

    const quote = <TellUsMore />

    return (
        <Layout>
            <CriticalHead title={name} description={description} />
            <FormsModal />
            <ObjectsFormModal idOfInterest={objectID} />
            <ObjectsRentModal idOfInterest={objectID} name={name} />
            {
                presentation !== null &&
                <ObjectsPresentationModal idOfInterest={objectID} name={name} file={presentation.asset} coverLogo={imageRepresentativeURL} />
            }
            <article className="mansionObject">
                <FirstScreen name={name} image={data.mansion.imgFront} />
                <section className={`objectDescription`}>
                    <PriceSection
                        priceOnDemand={priceOnDemand}
                        sellPossible={sellPossible}
                        rentPossible={rentPossible}
                        rentOccupied={rentOccupied}
                        currencyOfSell={currencyOfSell}
                        currencyOfRent={currencyOfRent}
                        priceToSell={priceToSell}
                        priceToRent={priceToRent}
                    />
                    <ObjectDetails 
                        houseArea={houseArea}
                        houseBedrooms={houseBedrooms}
                        houseFloors={houseFloors}
                        objectID={objectID}
                        housePool={housePool}
                        houseStatus={houseStatus}
                        houseYear={houseYear}
                        siteArea={siteArea}
                        siteBuildings={siteBuildings}
                        siteProperty={siteProperty}
                        siteStatus={siteStatus}
                        siteType={siteType}
                        siteResources={siteResources}
                    />
                    <CTAbutton CTA="Хочу посмотреть" /> 
                </section>
                <div className="divider-ornament" />
                {
                    data.mansion.imgGallery.length > 0 ? 
                    <ImageGallery images={data.mansion.imgGallery} className="ImageGallery" />
                    :
                    null
                }
                <LevelsDescription levelsData={houseLevelsDescription} />
                <div className="CTAblock">
                    {data.mansion.presetation !== null ? <GetDPFbutton CTA={"Презентация"} /> : null}
                    <CTAbutton CTA="Хочу посмотреть" />
                </div>
                <KoshevickiyIntroduction quote={quote} objectID={objectID} />
                <ToMainBottomLink />
            </article>
        </Layout>
    );
}

export default MansionObjectTemplate;